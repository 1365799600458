import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../notifcations/notifications.service';
import { Authentication } from 'app/shared/guard/authentication/Authentication';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private hcmsService: HCMSService, private notifications: NotificationsService, private translateService: TranslateService) { }

  public saveMail(mail, showMessage = true, message = '') {
    return this.hcmsService.get().all('entity/mail').post(mail, {}, { 'Authorization': Authentication.getAuthToken() }).toPromise().then(() => {

      if (showMessage && !message) {
        this.notifications.show(this.translateService.instant('mails.success'));
      } else {
        this.notifications.show(this.translateService.instant(message));
      }
    });
  }

  public editMail(mail) {
    return this.hcmsService.get().one('entity/mail', mail.id).get({}, { 'Authorization': Authentication.getAuthToken() }).toPromise().then(x => {
      x = Object.assign(x, mail);

      return x.put();
    });
  }

  public getMails(usage: string = '', asset: number = null, user: number = null, sent = null) {
    let params = [];

    if (usage) {
      params.push('usage="' + usage + '"');
    }
    if (asset) {
      params.push('asset=' + asset);
    }
    if (user) {
      params.push('user=' + user);
    }
    if (sent != null) {
      params.push('sent!=' + sent);
    }

    return this.hcmsService.get().one('entity/mail?query=' + params.join('%26')).get({}, { 'Authorization': Authentication.getAuthToken() }).toPromise();

  }
}
