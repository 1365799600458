import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OCService } from '../satellites/oc.service';
import { LiquidCache, LiquidCacheService } from 'ngx-liquid-cache';
import { AppSettings } from 'app/shared/app.settings';
import { User, UserPicture } from 'app/shared/model/user.model';
import { HCMSService } from '../satellites/hcms.service';
import { RolesService } from './roles.service';
import Utils from 'app/shared/utils/utils';

@Injectable()
export class UserService {

  static readonly RELATIONS_LIMIT = 150;

  static readonly LAST_USER_TIME = "last_user_time";

  static readonly WORKFLOW_WEBUSER_STEP_NOT_CONFIRMED: number = 10;
  static readonly WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE: number = 20;
  static readonly WORKFLOW_WEBUSER_STEP_APPROVED: number = 30;
  static readonly WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED: number = 40;

  static readonly OWNER = "owner_rel";
  static readonly PROJECT_MANAGER = "projectmanager_rel";
  static readonly CO_OWNER = "co-owner";
  static readonly CO_MANAGER = "co-manager";
  static readonly DECISION_MAKER = "decision-maker";
  static readonly CONTRIBUTOR = "contributor";
  static readonly READER = "reader";

  static readonly REMOVE_OWNER = "removeOwner";
  static readonly REMOVE_PROJECT_MANAGER = "removeProjectmanager";
  static readonly REMOVE_CO_OWNER = "removeCoowner";
  static readonly REMOVE_CO_MANAGER = "removeComanager";
  static readonly REMOVE_DECISION_MAKER = "removeDecisionmaker";
  static readonly REMOVE_CONTRIBUTOR = "removeContributor";
  static readonly REMOVE_READER = "removeReader";

  static readonly OWNER_RELATION = { 'key': UserService.OWNER, 'remove': UserService.REMOVE_OWNER };
  static readonly DEPUTY_RELATION = { 'key': UserService.CO_OWNER, 'remove': UserService.REMOVE_CO_OWNER };
  static readonly PROJECT_MANAGER_RELATION = { 'key': UserService.PROJECT_MANAGER, 'remove': UserService.REMOVE_PROJECT_MANAGER };
  static readonly ASSISTANT_MANAGER_RELATION = { 'key': UserService.CO_MANAGER, 'remove': UserService.REMOVE_CO_MANAGER };
  static readonly DECISION_MAKER_RELATION = { 'key': UserService.DECISION_MAKER, 'remove': UserService.REMOVE_DECISION_MAKER };
  static readonly CONTRIBUTOR_RELATION = { 'key': UserService.CONTRIBUTOR, 'remove': UserService.REMOVE_CONTRIBUTOR };
  static readonly READER_RELATION = { 'key': UserService.READER, 'remove': UserService.REMOVE_READER };

  private teams: Array<any> = [
    //OWNER
    {
      'key': UserService.OWNER, 'remove': UserService.REMOVE_OWNER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.POWER_ROLE, RolesService.LIMITED_ROLE]
    },
    //DEPUTY
    {
      'key': UserService.CO_OWNER, 'remove': UserService.REMOVE_CO_OWNER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.POWER_ROLE, RolesService.LIMITED_ROLE, RolesService.CREW_ROLE]
    },
    //MANAGER
    {
      'key': UserService.PROJECT_MANAGER, 'remove': UserService.REMOVE_PROJECT_MANAGER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE]
    },
    //ASSISTANT MANAGER
    {
      'key': UserService.CO_MANAGER, 'remove': UserService.REMOVE_CO_MANAGER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE]
    },
    //DECISION MAKER
    {
      'key': UserService.DECISION_MAKER, 'remove': UserService.REMOVE_DECISION_MAKER, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.POWER_ROLE, RolesService.CREW_ROLE]
    },
    //CONTRIBUTOR
    {
      'key': UserService.CONTRIBUTOR, 'remove': UserService.REMOVE_CONTRIBUTOR, 'roles':
        [RolesService.ADMIN_ROLE, RolesService.PROJECT_MANAGER_ROLE, RolesService.POWER_ROLE, RolesService.LIMITED_ROLE, RolesService.CREW_ROLE, RolesService.CONTRIBUTOR_ROLE]
    },
    //READER
    { 'key': UserService.READER, 'remove': UserService.REMOVE_READER }
  ];

  private users = [];

  constructor(private ocService: OCService, private hcmsService: HCMSService, private rolesService: RolesService, private cache: LiquidCacheService) {

    //this.checkLastUser();

    if (this.getCurrentUser()) {
      this.loadUsers();
    }

  }

  @LiquidCache('webusers', { duration: 14400 })
  getWebUsers(): Observable<any> {
    return this.hcmsService.get().one('entity/webuser?limit=-1').get();
  }

  @LiquidCache('languages')
  getLanguages(): Observable<any> {
    return this.ocService.get().all('tables/language/').getList();
  }

  filterByUserRelation(id: number = 0) {

    if (id == 0) id = this.getCurrentUser().id;

    let query = "";
    this.teams.forEach(team => query += team.key + '=' + id + '|');

    return query.substring(0, query.length - 1);

  }

  getUsers() {
    return this.users.sort((x, y) => (x.name > y.name ? 1 : -1));
  }

  async getUsersByEmail(emails) {
    let cachedUsers = this.getUsers();
    return cachedUsers.filter(x => emails.includes(x.email));
  }

  getAdmins() {
    return this.hcmsService.get().one('entity/admin?query=roles="' + RolesService.ADMIN_ROLE + '"').get();
  }


  loadUsers() {
    if (this.getCurrentUser()) {
      this.getWebUsers().subscribe(data => {
        if (data.result) {
          data.result.forEach(user => this.fillUserData(user));
          this.users = data.result;
          this.saveLastUserTime();
        }
      });
    }

  }

  async checkLastUser() {

    let time = await this.ocService.get().one('last_user_time').get().toPromise()
      .then(result => {
        return result.time;
      });

    let current = this.getLastUserTime();
    if (time > current) {
      this.cache.remove('webusers');
      localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + UserService.LAST_USER_TIME);
      this.loadUsers();
    }
  }

  saveLastUserTime() {

    if (this.users.length > 0) {
      let usersChanged = this.users.filter(user => user.modifiedAttr).map(user => user.modifiedAttr)
        .sort((a, b) => {
          return new Date(b).getTime() - new Date(a).getTime();
        });

      if (usersChanged.length > 0) {
        let time = new Date(usersChanged[0]).getTime();
        localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + UserService.LAST_USER_TIME, JSON.stringify(time));
      }
    }
  }

  getLastUserTime() {
    let time = localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + UserService.LAST_USER_TIME);
    if (!time) return 0;

    return time;
  }

  getSelectablesUsersByRole(role: string) {
    let selectableUsers = this.getSelectablesUsers();
    return selectableUsers.filter(user => this.rolesService.checkRole(user, role));
  }

  getSelectablesUsers() {
    return this.getUsers().filter(x => this.isSelectable(x));
  }

  isSelectable(user: User): boolean {
    return !(user.roles && user.roles.length === 1 && user.roles[0].toLowerCase().indexOf('admin') > -1);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
  }

  updateCurrentUser(user) {
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE, JSON.stringify(user));
  }

  setCurrenUser(user) {
    this.fillUserData(user);
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE, JSON.stringify(user));
  }

  getUserLanguage() {
    let user = this.getCurrentUser();
    if (user && user.language) return user.language;
  }

  removeCurrenUser() {
    localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE);
  }

  getUserData(id) {

    if (typeof id === 'number' || typeof id === 'string') {
      return this.users.find(user => user.id == id);
    } else if (id instanceof Array && id.length > 0) {
      return this.users.find(user => user.id == id[0]);
    }

  }

  addWatchlist(id) {
    let user = this.getCurrentUser();

    if (!user.watchlist) {
      user.watchlist = [];
    }

    user.watchlist.push(id);
    this.updateWatchlist(user);
  }

  removeWatchlist(id) {
    let user = this.getCurrentUser();

    if (!user.watchlist) {
      user.watchlist = [];
    }

    const index = user.watchlist.indexOf(id);
    if (index > -1) {
      user.watchlist.splice(index, 1);
    }
    this.updateWatchlist(user);
  }

  getTeams(asset): Map<string, Array<any>> {

    let team: Map<string, Array<any>> = new Map();
    this.teams.forEach(x => {
      let usersTeam = this.getRelationUsers(asset, x.key, x.remove);
      if (usersTeam) {
        team.set(x.key, usersTeam);
      }
    });

    return team;
  }

  getRelationUsers(asset, relation, removeField): Array<User> {
    let users: Array<User> = new Array();

    if (asset[relation] && asset[relation].length > 0) {
      asset[relation].forEach(current => {
        if (current && (!asset[removeField] || !asset[removeField].includes(current))) {
          let userData = this.getUserData(current);
          if (userData) {
            users.push(userData);
          }
        }
      });
    }

    return users;
  }

  getRelationUser(asset, relation, removeField) {
    let user;
    if (asset[relation] && asset[relation].length > 0) {
      asset[relation].forEach(current => {
        if (current && (!asset[removeField] || asset[removeField] != current)) {
          user = this.getUserData(current);
        }
      });
    }

    if (user) {
      asset[relation] = [];
      asset[relation].push(user.id);
    }

    return user;
  }

  saveRelationUser(asset, relation, valueField, removeField) {

    if (asset[valueField] && (!asset[relation] || asset[relation].length == 0 || (asset[valueField] != asset[relation][0]))) {

      if (asset[relation] && asset[relation].length > 0) {
        asset[removeField] = asset[relation][0];
      }

      asset[relation] = [];
      asset[relation].push(asset[valueField]);

    }
  }

  private updateWatchlist(user) {
    this.setCurrenUser(user);
    this.getUser(user).then(data => {
      data.watchlist = user.watchlist;
      data.put();
    });
  }

  private getUser(user: User) {
    return this.hcmsService.get().one('entity/webuser', user.id).get().toPromise();
  }

  private fillUserData(user) {

    user.initials = '';
    user.display_name = '';

    if (user.pictures && user.pictures.length > 0 && user.pictures[0].downloadLink) {
      user.mainPicture = user.pictures[0];
      user.mainPicture.downloadLink = user.mainPicture.downloadLink + '?version=' + user.mainPicture.version;

    }

    if (user.type && user.type.indexOf('webuser') > -1) {

      if (user.firstName) {
        user.initials += user.firstName.charAt(0);
        user.display_name += user.firstName + ' ';
      }

      if (user.lastName) {
        user.initials += user.lastName.charAt(0);
        user.display_name += user.lastName;
      }
    } else {

      if (user.firstname) {
        user.initials += user.firstname.charAt(0);
        user.display_name += user.firstname + ' ';
      }

      if (user.name) {
        user.initials += user.name.charAt(0);
        user.display_name += user.name;
      }
    }

  }

  changePicture(user: User, file, type) {

    let picture: UserPicture = new UserPicture();
    picture.type = type.def_assettype;
    picture.name = file.name;
    picture.downloadLink = "formdata:file0";
    picture.domain = user.domain;
    picture.reloadPreview = true;

    user.pictures = [picture];
    delete user.workflowStep;

    let fileFormData = new FormData();
    fileFormData.append("entity", JSON.stringify(user));
    fileFormData.append("file0", file);


    return this.hcmsService.get().all('entity/webuser/' + user.id).customPUT(fileFormData, '', {}, {}).toPromise().then((newUser) => {
      this.changeUser(newUser.plain());
    });
  }

  changeUser(user: User) {

    this.fillUserData(user);
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE, JSON.stringify(user));

    for (var i in this.users) {
      if (+this.users[i].id === +user.id) {
        this.users[i] = user;
        break;
      }
    }
    setTimeout(() => {
      Utils.reloadData = Utils.RELOAD_DATA_CURRENT_USER_DATA;
    }, 1500);
  }

  getAddMemberRoles(userid: number = 0) {
    let user: User = this.getCurrentUser();
    if (userid > 0) user = this.getUserData(userid);
    return this.teams.filter(x => !x.roles || x.roles.some(y => this.rolesService.checkRole(user, y)));
  }

  getTeamByRole(role) {
    return this.teams.find(x => x.key === role);
  }

  hasRole(key) {

    let currentUser = this.getCurrentUser();
    return this.teams.some(x => x.key == key && (!x.roles || x.roles.some(y => this.rolesService.checkRole(currentUser, y))));
  }

  getTeam(id: number) {
    return this.hcmsService.get().one('entity/team', id).get().toPromise();
  }

  async saveNewUserInTeam(team, member) {

    this.addUserToTeam(team, member.role, member.person);

    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, team);

    return updateTeam.save().toPromise()

  }

  async removeUserFromTeam(team, role, user) {

    this.removeUserToTeam(team, role, user);

    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, team);

    return updateTeam.save().toPromise()

  }

  async changeRoleInTeam(team, oldRole, role, user) {

    this.addUserToTeam(team, role, user);
    this.removeUserToTeam(team, oldRole, user);

    let route = Utils.getTeamEntity(team.type);
    let updateTeam = await this.hcmsService.get().one(route, team.id).get().toPromise();
    updateTeam = Object.assign(updateTeam, team);
    return updateTeam.save().toPromise()

  }

  private addUserToTeam(team, role, user) {
    if (team[role] == null) team[role] = [];
    team[role].push(+user);

    let field = Utils.getSchemaRemoveFeature(role);
    if (team[field] != null && team[field].includes(+user)) {
      const index = team[field].indexOf(+user);
      if (index > -1) {
        team[field].splice(index, 1);
      }
    }
  }

  private removeUserToTeam(team, role, user) {
    let field = Utils.getSchemaRemoveFeature(role);
    if (team[field] == null) team[field] = [];
    team[field].push(+user);
  }

  canEditTeam(user, team) {
    let roles = [RolesService.PROJECT_MANAGER_ROLE, RolesService.POWER_ROLE];
    let relations = [UserService.OWNER_RELATION, UserService.DEPUTY_RELATION, UserService.PROJECT_MANAGER_RELATION, UserService.ASSISTANT_MANAGER_RELATION];
    return this.canEdit(user, team, roles, relations);
  }

  canEditMaterials(user, team) {
    let roles = [RolesService.PROJECT_MANAGER_ROLE];
    let relations = [UserService.PROJECT_MANAGER_RELATION, UserService.ASSISTANT_MANAGER_RELATION];
    return this.canEdit(user, team, roles, relations);
  }

  canEditApproval(user, team) {
    let roles = [RolesService.PROJECT_MANAGER_ROLE, RolesService.POWER_ROLE, RolesService.CREW_ROLE];
    let relations = [UserService.PROJECT_MANAGER_RELATION, UserService.ASSISTANT_MANAGER_RELATION, UserService.OWNER_RELATION, UserService.DEPUTY_RELATION];
    return this.canEdit(user, team, roles, relations);
  }

  canEdit(user, team, roles, relations) {
    if (this.rolesService.checkRole(user, RolesService.ADMIN_ROLE)) return true;
    if (!team) return false;
    return roles.some(role => this.rolesService.checkRole(user, role)) && relations.some(relation => this.getRelationUsers(team, relation.key, relation.remove).some(x => x.id == user.id));
  }

  copyTeam(parentid, child) {
    return this.getTeam(parentid).then(async parentTeam => {
      if (parentTeam) {

        let route = Utils.getTeamEntity(child.type);
        let childTeam = await this.hcmsService.get().one(route, child.id).get().toPromise();
        this.teams.forEach(team => {
          if (parentTeam[team.key] != null) {
            childTeam[team.key] = parentTeam[team.key];
          }
        });
        return childTeam.save().toPromise();
      }
    });
  }

  getFilteredMemberRoles(user, owner, pm) {
    let roles = this.getAddMemberRoles(user);

    if (owner) roles = roles.filter(role => role.key != UserService.OWNER);
    if (pm) roles = roles.filter(role => role.key != UserService.PROJECT_MANAGER);

    return roles;

  }

}
