import { Injectable } from '@angular/core';
import { LiquidCache } from 'ngx-liquid-cache';
import { Observable } from 'rxjs';
import { OCService } from '../satellites/oc.service';
import { User } from 'app/shared/model/user.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  static readonly MAIN_ROLE = "root.cntr."
  static readonly POWER_ROLE = "root.cntr.power.";
  static readonly PROJECT_MANAGER_ROLE = "root.cntr.manager.";
  static readonly ADMIN_ROLE = "root.cntr.admin.";
  static readonly CONTRIBUTOR_ROLE = "root.cntr.contributor.";
  static readonly CREW_ROLE = "root.cntr.crew.";
  static readonly LIMITED_ROLE = "root.cntr.limited.";
  static readonly READER_ROLE = "root.cntr.reader.";
  static readonly SUPERVISOR_ROLE = "root.cntr.supervisor.";

  static readonly DEFAULT_ROLE = RolesService.POWER_ROLE;

  roles = [];

  constructor(private ocService: OCService) {
    this.getRoles().subscribe(roles => this.roles = roles);
   }

  @LiquidCache('roles')
  getRoles(): Observable<any> {
    return this.ocService.get().all('tables/feature_value/feature/censhare:module.oc.permission.group/value_key/' + RolesService.MAIN_ROLE + '*').getList();
  }

  hasRelationAndWithRole(asset: any, user: User, relation: string, role: string) {
    return this.hasRelation(asset, user, relation) && this.checkRole(user, role);
  }

  checkRole(user: User, role: string): boolean {
    if (user && user.roles) {
      return user.roles.indexOf(role) > -1;
    }

    return false;
  }

  /**
   * Check if the user's role is admin user.
   * @param user user
   * @returns true if the role is admin user
   */
  isAdmin(user: User){
    return this.checkRole(user, RolesService.ADMIN_ROLE);
  }

  hasRelation(asset: any, user: User, relation: string): boolean {
    if (user && asset && asset[relation]) {
      if (asset[relation] instanceof Array && asset[relation].length > 0) {
          return asset[relation][0] === user.id;
      }
    }

    return false;
  }


  getRoleName(roleKey: string) {
    const fullRole = this.roles.filter(role => role.value_key === roleKey);
    if (fullRole && fullRole.length > 0) {
      return fullRole[0].name;
    }
  }
}
