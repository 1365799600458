import { AppSettings } from "../app.settings";
import { environment } from "environments/environment";

export default class Utils {

  static showTermsOfUse: boolean = false;
  static iframeOpened: boolean = false;

  static resetTab: boolean = false;

  static loadingData: boolean = false;

  // -1: None, -2: Project list, -3: Actions list, -4: Current User data
  static readonly RELOAD_DATA_NONE: number = -1;
  static readonly RELOAD_DATA_PROJECTS_LIST: number = -2;
  static readonly RELOAD_DATA_ACTIONS_LIST: number = -3;
  static readonly RELOAD_DATA_CURRENT_USER_DATA: number = -4;

  static reloadData: number = Utils.RELOAD_DATA_NONE;

  static getFileExtension(filename) {
    return '.' + filename.split('.').pop();
  }

  static getFilenameWithoutExtension(filename) {
    return filename.split('.').slice(0, -1).join('.');
  }

  static getMillis(date): number {
    return new Date(date).getTime();
  }

  static getMillisDate(date: Date): number {
    return date.getTime();
  }

  static getHttpAction(url: string) {

    if (url.indexOf(environment.baseHCSMUrl) > -1) {
      url = url.replace(environment.baseHCSMUrl + 'entity/', '');
      let nodes = url.split('/');
      if (nodes.length >= 1) {
        url = nodes[0];
      }
    }

    if (url.indexOf(environment.baseOCUrl) > -1) {
      url = url.replace(environment.baseOCUrl, '');
    }

    return url;
  }

  static getTeamEntity(assetType: string) {
    switch (assetType) {
      case 'project.':
        return 'entity/teamprojectupdate';
      case 'order.':
        return 'entity/teamactionupdate';
    }
  }

  static getEntity(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return 'entity/imageupdate';
      case 'video.':
        return 'entity/videoupdate';
      case 'pdf.':
        return 'entity/pdfupdate';
      case 'audio.':
        return 'entity/audioupdate';
      case 'web-page.':
        return 'entity/webpageupdate';
      case 'order.':
        return 'entity/actionupdate';
      case 'project.':
        return 'entity/project';
      case 'group.':
        return 'entity/groupupdate';
      case 'presentation.':
        return 'entity/presentationupdate';
      case 'office.':
        return 'entity/officeupdate';
      case 'spreadsheet.':
        return 'entity/spreadsheetupdate';
      case 'zip.':
        return 'entity/zipupdate';
    }
  }

  static getById(id: string, array: any[]) {
    let element = array.find((x: any) => x.id == id);
    return element ? element : { name: '' };
  }

  static getEncodedValue(value: string): string {
    return encodeURIComponent(encodeURIComponent(btoa(value)));
  }

  static getIcon(assetType: string) {
    switch (assetType) {
      case 'web-page.':
        return 'web';
      case 'pdf.':
        return 'picture_as_pdf';
      case 'audio.':
        return 'music_video';
      case 'group.':
        return 'folder';
      case 'picture.':
        return 'photo';
      case 'video.':
        return 'movie';
      case 'presentation.':
        return 'photo_library';
      case 'zip.':
        return 'create_new_folder';
      default:
        return 'attachment';
    }
  }

  static componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  static dec2hex(number) {
    var intnumber = number - 0,
      red, green, blue;
    red = (intnumber >> 16) & 0xFF;
    green = (intnumber >> 8) & 0xFF;
    blue = intnumber & 0xFF;
    return '#' + this.componentToHex(red) + this.componentToHex(green) + this.componentToHex(blue);
  }

  static getWorkflowId(assetType: string) {
    switch (assetType) {
      case 'picture.':
        return AppSettings.WORKFLOW_ID_PICTURE;
      case 'video.':
        return AppSettings.WORKFLOW_ID_VIDEO;
      case 'pdf.':
        return AppSettings.WORKFLOW_ID_PDF;
      case 'audio.':
        return AppSettings.WORKFLOW_ID_AUDIO;
      case 'office.':
        return AppSettings.WORKFLOW_ID_OFFICE;
      case 'spreadsheet.':
        return AppSettings.WORKFLOW_ID_SPREADSHEET;
      case 'presentation.':
        return AppSettings.WORKFLOW_ID_PRESENTATION;
      case 'zip.':
        return AppSettings.WORKFLOW_ID_ZIP;
      case 'web-page.':
        return AppSettings.WORKFLOW_ID_WEBPAGE;
    }
  }

  static equal(a, b): boolean {
    let result: boolean = true;

    if (a !== Object(a) || b !== Object(b)) {
      return a == b;
    }
    if (a != b) {
      if (a && b) {
        if (Object.keys(a).length > 0 && Object.keys(b).length > 0) {

          if (Object.keys(a).length == Object.keys(b).length) {
            Object.keys(a).forEach(x => {
              if (result) {
                result = this.equal(a[x], b[x]);
              }
            });
          } else {
            result = false;
          }

        } else {
          result = a.length == 0 && b.length == 0;
        }
      } else {
        result = false;
      }
    }

    return result;
  }

  static randomHash() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  static capitalize(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  static getSchemaRemoveFeature(s) {
    switch (s) {
      case 'Responsible person':
      case 'owner_rel':
        return 'removeOwner';
      case 'Project Manager':
      case 'projectmanager_rel':
        return 'removeProjectmanager';
      case 'Deputy':
      case 'co-owner':
        return 'removeCoowner';
      case 'Assistant Manager':
      case 'co-manager':
        return 'removeComanager';
      case 'Decision-Maker':
      case 'decision-maker':
        return 'removeDecisionmaker';
      case 'Contributor':
      case 'contributor':
        return 'removeContributor';
      case 'Reader':
      case 'reader':
        return 'removeReader';
    }
  }

  static rot13 = function (input) {
    return input.replace(/[a-zA-Z]/g, function (c) {
      return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
    });
  };

  static replaceHttp(s) {
    // return s.replace('http://', 'https://');
    return s;
  }

  static espaceHtml(s) {

    const values = [
      { key: '&', value: '&amp;' },
      { key: 'Á', value: '&Aacute;' },
      { key: 'á', value: '&aacute;' },
      { key: 'À', value: '&Agrave;' },
      { key: 'Â', value: '&Acirc;' },
      { key: 'à', value: '&agrave;' },
      { key: 'Â', value: '&Acirc;' },
      { key: 'â', value: '&acirc;' },
      { key: 'Ä', value: '&Auml;' },
      { key: 'ä', value: '&auml;' },
      { key: 'Ã', value: '&Atilde;' },
      { key: 'ã', value: '&atilde;' },
      { key: 'Å', value: '&Aring;' },
      { key: 'å', value: '&aring;' },
      { key: 'Æ', value: '&Aelig;' },
      { key: 'æ', value: '&aelig;' },
      { key: 'Ç', value: '&Ccedil;' },
      { key: 'ç', value: '&ccedil;' },
      { key: 'Ð', value: '&Eth;' },
      { key: 'ð', value: '&eth;' },
      { key: 'É', value: '&Eacute;' },
      { key: 'é', value: '&eacute;' },
      { key: 'È', value: '&Egrave;' },
      { key: 'è', value: '&egrave;' },
      { key: 'Ê', value: '&Ecirc;' },
      { key: 'ê', value: '&ecirc;' },
      { key: 'Ë', value: '&Euml;' },
      { key: 'ë', value: '&euml;' },
      { key: 'Í', value: '&Iacute;' },
      { key: 'í', value: '&iacute;' },
      { key: 'Ì', value: '&Igrave;' },
      { key: 'ì', value: '&igrave;' },
      { key: 'Î', value: '&Icirc;' },
      { key: 'î', value: '&icirc;' },
      { key: 'Ï', value: '&Iuml;' },
      { key: 'ï', value: '&iuml;' },
      { key: 'Ñ', value: '&Ntilde;' },
      { key: 'ñ', value: '&ntilde;' },
      { key: 'Ó', value: '&Oacute;' },
      { key: 'ó', value: '&oacute;' },
      { key: 'Ò', value: '&Ograve;' },
      { key: 'ò', value: '&ograve;' },
      { key: 'Ô', value: '&Ocirc;' },
      { key: 'ô', value: '&ocirc;' },
      { key: 'Ö', value: '&Ouml;' },
      { key: 'ö', value: '&ouml;' },
      { key: 'Õ', value: '&Otilde;' },
      { key: 'õ', value: '&otilde;' },
      { key: 'Ø', value: '&Oslash;' },
      { key: 'ø', value: '&oslash;' },
      { key: 'ß', value: '&szlig;' },
      { key: 'Þ', value: '&Thorn;' },
      { key: 'þ', value: '&thorn;' },
      { key: 'Ú', value: '&Uacute;' },
      { key: 'ú', value: '&uacute;' },
      { key: 'Ù', value: '&Ugrave;' },
      { key: 'ù', value: '&ugrave;' },
      { key: 'Û', value: '&Ucirc;' },
      { key: 'û', value: '&ucirc;' },
      { key: 'Ü', value: '&Uuml;' },
      { key: 'ü', value: '&uuml;' },
      { key: 'Ý', value: '&Yacute;' },
      { key: 'ý', value: '&yacute;' },
      { key: 'ÿ', value: '&yuml;' },
      { key: '©', value: '&copy;' },
      { key: '®', value: '&reg;' },
      { key: '™', value: '&trade;' },
      { key: '<', value: '&lt;' },
      { key: '>', value: '&gt;' },
      { key: '€', value: '&euro;' },
      { key: '¢', value: '&cent;' },
      { key: '£', value: '&pound;' },
      { key: '"', value: '&quot;' },
      { key: '‘', value: '&lsquo;' },
      { key: '’', value: '&rsquo;' },
      { key: '“', value: '&ldquo;' },
      { key: '”', value: '&rdquo;' },
      { key: '«', value: '&laquo;' },
      { key: '»', value: '&raquo;' },
      { key: '—', value: '&mdash;' },
      { key: '–', value: '&ndash;' },
      { key: '°', value: '&deg;' },
      { key: '±', value: '&plusmn;' },
      { key: '¼', value: '&frac14;' },
      { key: '½', value: '&frac12;' },
      { key: '¾', value: '&frac34;' },
      { key: '×', value: '&times;' },
      { key: '÷', value: '&divide;' },
      { key: 'α', value: '&alpha;' },
      { key: 'β', value: '&beta;' },
      { key: '∞', value: '&infin;' }
    ];

    let value = s;

    values.forEach(x => {
      value = value.replace(new RegExp(x.key, 'g'), x.value);
    });

    return value;
  }

  static getNumDaysBetween(d1: Date, d2: Date) {
    let newD1 = new Date(d1.getUTCFullYear(), d1.getUTCMonth(), d1.getUTCDate(), 0, 0, 0, 0);
    let newD2 = new Date(d2.getUTCFullYear(), d2.getUTCMonth(), d2.getUTCDate(), 0, 0, 0, 0);
    var diff = newD2.getTime() - newD1.getTime();
    return diff / (1000 * 60 * 60 * 24);
  }

  static parseDate(input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    var parts = input.match(/(\d+)/g),
      i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
  }
}
