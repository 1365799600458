import { AppSettings } from "../app.settings";

export class Asset {
    name : string;
    id : number;
    domain : string = AppSettings.DOMAIN;
    domain2 : string;
    type: string;
    created: string;
    createdAttr: string;
    info : string;
    workflow: number;
    workflowStep: number = 10;
    workflowTarget: number;
    createdBy: number;
    modifiedBy: number;
    modifiedAttr: string;
    image: string;
    needsApproval: boolean;

    markDeletion: number;
    markForDeletion: boolean;

    downloads;
    tags: any[];
}