import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { Collection } from 'app/shared/model/collection.model';
import { UserService } from '../user/user.service';
import { AppSettings } from 'app/shared/app.settings';
import { Media } from 'app/shared/model/media.model';
import { Mail } from 'app/shared/model/mail.model';
import { MailService } from '../mail/mail.service';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  private COLLECTION_STORAGE: string = "LoggedInCollection";

  constructor(private hcmsService: HCMSService, private usersService: UserService, private mailService: MailService) { }

  public getCollectionByUser(userid) {
    return this.hcmsService.get().one('entity/collection?query=user=' + userid + '%26markDeletion!=1').get().toPromise();
  }

  public getHowToCollection() {
    return this.hcmsService.get().one('entity/collection?query=howTo').get().toPromise();
  }

  public loadUserCollection() {
    let currentUser = this.usersService.getCurrentUser();

    if (currentUser) {
      this.getCollectionByUser(currentUser.id).then(data => {
        if (data.result && data.result.length > 0) {
          this.updateUserCollection(data.result.filter(x => !x.markForDeletion));
        } else {
          this.createCollection(currentUser.id);
        }
      });
    }

  }

  getUserCollection() {
    return JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + this.COLLECTION_STORAGE));
  }

  updateUserCollection(collection) {
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + this.COLLECTION_STORAGE, JSON.stringify(collection));
  }

  removeUserCollection() {
    localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + this.COLLECTION_STORAGE);
  }

  public getCollectionById(id) {
    return this.hcmsService.get().one('entity/collection', id).get().toPromise();
  }

  createCollection(userid, name = 'Collection') {
    let collection = new Collection();
    collection.name = name;
    collection.user = [+userid];

    delete collection.created;
    delete collection.createdAttr;

    return this.hcmsService.get().all('entity/collection').post(collection).toPromise().then(result => {
      if (result) {
        let user = this.usersService.getCurrentUser();
        user.collection = result.id;
        this.usersService.updateCurrentUser(user);

        return result;
      }
    });

  }

  addAssetsToCollection(assets: Array<Number>, collection: Collection) {
    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      assets.forEach(asset => {
        if (!col.assets.includes(asset)) {
          col.assets.push(asset);
        }
      });

      return col.put().toPromise();
    });
  }

  removeAssetsFromCollection(assets: Array<Number>, collection: Collection) {

    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      assets.forEach(asset => {
        const index = col.assets.indexOf(asset);
        if (index > -1) {
          col.assets.splice(index, 1);
        }
      });

      //col.removeRelation = +asset.id;

      return col.put().toPromise();
    });
  }

  removeAssetFromCollection(asset: Media, collection: Collection) {
    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      const index = col.assets.indexOf(asset.id);
      if (index > -1) {
        col.assets.splice(index, 1);
      }

      col.removeRelation = +asset.id;

      return col.put().toPromise();
    });
  }

  deleteCollection(collection: Collection) {

    return this.getCollectionById(collection.id).then(col => {
      col.markForDeletion = true;

      return col.put().toPromise();

    });
  }

  async saveCollection(collection: Collection) {
    let newCollection = Object.assign(new Collection, this.hcmsService.get().copy(collection).plain());
    delete newCollection.assets;

    let updateCollection = await this.hcmsService.get().one('entity/collection', newCollection.id).get().toPromise();
    updateCollection = Object.assign(updateCollection, newCollection);

    return updateCollection.save().toPromise();
  }

  async shareCollection(collection: Collection, recipients) {

    /* let recipentsArray = recipients.split(',');
    recipentsArray = [].concat(...recipentsArray.map(x => x.split(';')));
    recipentsArray = recipentsArray.map(x => x.trim());*/

    let recipentsArray = recipients.map(x => x.email);

    const mailsTo = [];

    let assetIds = await this.usersService.getUsersByEmail(recipentsArray);

    if (assetIds) {
      assetIds = assetIds.map(x => x.id);

      this.hcmsService.get().one('entity/collection', collection.id).get().subscribe(x => {

        assetIds.forEach(y => {
          if (!x.user.includes(y)) {
            x.user.push(y);
            mailsTo.push(y);
          }
        });

        x.put().subscribe(result => {

          mailsTo.forEach(y => {
            let mail: Mail = new Mail(AppSettings.MAIL_SHARE_COLLECTION + '_' + collection.id + '_' + y, AppSettings.MAIL_SHARE_COLLECTION, +collection.id, +y);
            this.mailService.saveMail(mail, true, 'sammlung_erfolgreich_geteilt');
          });

        });
      });
    }
  }
}
