import { AppSettings } from './shared/app.settings';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private translate: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {

        iconRegistry.addSvgIcon('library', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_library.svg'));
        iconRegistry.addSvgIcon('img', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_img.svg'));

        translate.setDefaultLang(AppSettings.APP_DEFAULT_LANGUAGE);

    }

    ngOnInit() {
    }

    preventDefaultEvent(event) {
        event.preventDefault();
    }
}
