import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppSettings } from 'app/shared/app.settings';

if (environment.production) {
  enableProdMode();
}

// if (environment.production && AppSettings.ATI_TRACKING_ID != '') {
//   document.write('<script src="//tag.aticdn.net/' + AppSettings.ATI_TRACKING_ID + '/smarttag.js" async></script>');
// }

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
