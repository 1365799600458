import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LiquidCache } from 'ngx-liquid-cache';
import { OCService } from '../satellites/oc.service';
import { AppSettings } from 'app/shared/app.settings';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WorkflowService {

    private workflows = [];
    private workflowSteps = [];
    private workflowStates = [];

    static readonly STATUS_PENDING : string = 'Pending';
    static readonly STATUS_REJECTED : string = 'Rejected';
    static readonly STATUS_APPROVED : string = 'Approved';

    static readonly STATUS_EXPIRED : string = 'Expired';
    static readonly STATUS_ARCHIVE : string = 'Archive';

    actionInApproval = {'workflow': AppSettings.WORKFLOW_ACTION, 'step': 50};

    constructor(private ocService: OCService, private translateService: TranslateService) {

      this.getWorkflowStates().subscribe(data => this.workflowStates = data.filter(state => state.enabled == "1"));
      this.getWorkflows().subscribe(data => this.workflows = data);
      this.getWorkflowSteps().subscribe(data => this.workflowSteps = data);

    }

    @LiquidCache('workflowStates')
    getWorkflowStates(): Observable<any> {
      return this.ocService.get().all('tables/workflowState/').getList();
    }

    @LiquidCache('workflows')
    getWorkflows(): Observable<any> {
      return this.ocService.get().all('tables/workflow/').getList(); // + id/AppSettings.WORKFLOW_ID).getList();
    }

    @LiquidCache('workflowsSteps')
    getWorkflowSteps(): Observable<any> {
      return this.ocService.get().all('tables/workflowStep/').getList(); //wf_id/' + AppSettings.WORKFLOW_ID).getList();
    }

    isActionInApproval(step) {
      let inApproval = false;

      if (step) {
        return step.wf_id == this.actionInApproval.workflow && step.wf_step >= this.actionInApproval.step;
      }
      return inApproval;
    }

    getWorkflowStatus(step) {

      let status = '';

      if (step == 500) status = this.translateService.instant(WorkflowService.STATUS_PENDING.toLowerCase());
      if (step == 510) status = this.translateService.instant(WorkflowService.STATUS_REJECTED.toLowerCase());
      if (step == 520) status = this.translateService.instant(WorkflowService.STATUS_APPROVED.toLowerCase());
      if (step == 530) status = this.translateService.instant(WorkflowService.STATUS_EXPIRED.toLowerCase());
      if (step == 540) status = this.translateService.instant(WorkflowService.STATUS_ARCHIVE.toLowerCase());

      return status;

    }
    getStateWithWorkflowStep(wf_id, wf_step) {
      let step = this.getWorkflowStep(wf_id, wf_step);
      if (step) {
        return this.getStateById(step.wf_state_id);
      }
    }

    getWorkflowStepsByWorkflow(wf_id) {
      return this.workflowSteps.filter(step => step.wf_id == wf_id);
    }

    getWorkflowStep(wf_id, wf_step) {
      return this.workflowSteps.find(step => step.wf_id == wf_id && step.wf_step == wf_step);
    }

    getStateById(id) {
      return this.workflowStates.find(state => state.id == id);
    }

}
